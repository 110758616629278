import {configureStore} from "@reduxjs/toolkit"
import  searchResult from "./slice/search-result.slice";
import  reservations from "./slice/reservations-slice";
import  stopSale from "./slice/stopSale.slice";
import evenement from "./slice/evenement-slice";
export const store = configureStore({
    reducer: {
        searchResult,
        reservations,
        stopSale,
        evenement
    }
})
