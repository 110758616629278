import About from "./about";
import Plage from "./plage";
import Facilites from "./facilites";
import Secours from "./secours";
import Gallery from "./gallery";

export default function AboutSwitcher({current, data, images}){
    switch (current){
        case "about":
            return <About data={data}/>
        case "plage":
            return <Plage/>
        case "facilities":
            return <Facilites/>
        case "secours":
            return <Secours/>
        case "gallery":
            return <Gallery data={images}/>
        default :
            return <About/>
    }
}
