import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { reservationsActions } from "../app/slice/reservations-slice";
import ReservationService from "../service/reservation.service";
import axios from "axios";
const service = new ReservationService();

export default function ConfirmationReservationPaiement() {
  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem("reservation")));
    const reservation = JSON.parse(
      localStorage.getItem("reservation")
        ? localStorage.getItem("reservation")
        : null
    );
    if (reservation !== null) {
      service
        .save(reservation)
        .then((_) => {
          localStorage.removeItem("reservation");
          window.opener.postMessage('reloadPage', '*');
        })
        .catch((error) => console.log(error));
    }
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <img className="mx-auto" src="https://hotel-nesrine.com/logo.png" height={140} width={140} alt=""/>
      <p className="text-center">Nous vous remercions d'avoir choisi notre hôtel.</p>
      <p className="text-center">
        Votre réservation a été confirmée et nous avons envoyé un e-mail
        contenant le voucher de la réservation.
      </p>
      <strong className={"mt-2 text-center"}>
        Si vous avez des questions ou des préoccupations, n'hésitez pas à nous
        contacter. Nous sommes impatients de vous accueillir bientôt !
      </strong>
    </div>
  );
}
