import axios from "axios";
import { useState } from "react";
import { API_URL } from "../../config/constants";
import { redirect, useNavigate } from "react-router-dom";

export default function Login(){
    const [form, setForm] = useState({login: "", password: ""});
    const navigate = useNavigate()
    const handleFormChanged = e => {
        const {name, value} = e.target
        setForm({...form, [name]: value})
    }
    const submit = e => {
        e.preventDefault()
        axios.post(API_URL + "auth/login", form, {
            headers:{
                "Content-Type": "application/json"
            }
        })
        .then(res => {
            localStorage.setItem("token",res.data)
            navigate('/admin')
        })
        .catch(error => alert(error.response.data))
    }
    return (
        <div className="mt-5 d-flex justify-content-center w-100">
            <div className="card p-3">
                <img className="mx-auto" src="logo.png" width="80" alt="" />
                <form onSubmit={submit}>
                    <div className="form-group">
                        <label htmlFor="login">Login</label>
                        <input value={form.login} onChange={handleFormChanged} name="login" type="text" className="form-control" id="login"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Mot de passe</label>
                        <input value={form.password} onChange={handleFormChanged} name="password" type="password" className="form-control" id="password"/>
                    </div>
                    <button style={{padding: 20}} className="mt-2 btn btn-primary w-100">Se connecter</button>
                </form>
            </div>
        </div>
    )
}