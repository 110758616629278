import { useState } from "react";
import BaseService from "../service/base.service";
const service = new BaseService()

export default function Contact({data}) {
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });
  const submit = (e) => {
    setLoading(true);
    e.preventDefault();
    service.postContact(form).then((_) => {
      setShowMessage(true);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  };
  return (
    <div id={"contact"} className="container mt-5">
      <div className="row mt-4">
        <div className="col-12">
          <div className="font-back-tittle mb-45">
            <div className="archivment-front">
              <h3>Contactez nous</h3>
            </div>
            <h3 className="archivment-back">Contactez nous</h3>
          </div>
        </div>

        <div className="col-12 mb-5">
          <iframe
          title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12846.763554651974!2d10.5574515!3d36.3924764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd63dc7a49e34d%3A0x9aa5d603c9f7f739!2sNesrine!5e0!3m2!1sen!2stn!4v1679079778093!5m2!1sen!2stn"
            width="600"
            height="450"
            style={{ border: 0, width: "100%" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
        <div className="col-lg-8">
          <form
          onSubmit={submit}
            className="form-contact contact_form"
          >
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <textarea
                    className="form-control w-100"
                    name="message"
                    id="message"
                    cols="30"
                    rows="9"
                    placeholder="Votre message"
                    value={form.message}
                    onChange={e => setForm({...form, message: e.target.value})}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    className="form-control valid"
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Votre nom"
                    value={form.name}
                    onChange={e => setForm({...form, name: e.target.value})}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <input
                    className="form-control valid"
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={form.email}
                    onChange={e => setForm({...form, email: e.target.value})}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input
                    className="form-control"
                    name="subject"
                    id="subject"
                    type="text"
                    placeholder="Sujet"
                    value={form.subject}
                    onChange={e => setForm({...form, subject: e.target.value})}
                  />
                </div>
              </div>
            </div>
            <div className="form-group mt-3">
              <button
                type="submit"
                className="button button-contactForm boxed-btn"
              >
                Envoyer
              </button>
            </div>
          </form>
          {
              showMessage && 
              <p className="text-success">Nous avons bien reçu votre message et nous vous répondrons dans les plus brefs délais.</p>
            }
        </div>
        <div className="col-lg-3 offset-lg-1">
          <div className="media contact-info">
            <span className="contact-info__icon">
              <i className="ti-home"></i>
            </span>
            <div className="media-body">
              <h3>Localisation</h3>
              <p>{data.localisation}</p>
            </div>
          </div>
          <div className="media contact-info">
            <span className="contact-info__icon">
              <i className="ti-tablet"></i>
            </span>
            <div className="media-body">
              <h3>Téléphone</h3>
              <p>{data.tel1}</p>
            </div>
          </div>
          <div className="media contact-info">
            <span className="contact-info__icon">
              <i className="ti-email"></i>
            </span>
            <div className="media-body">
              <h3>email</h3>
              <p>{data.email1}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
