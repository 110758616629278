export default function Plage(){
    return (
        <div className="col-12 col-md-9 about-plage row">
            <img src="/assets/img/about/plage.jpg" alt="" className="col-12 col-md-4"/>
            <div className={"plage-details col-12 col-md-7"}>
                <ul>
                    <li><strong>Distance de l'hôtel: </strong><span>Hôtel donnant directement sur la mer (300 m jardin) </span></li>
                    <li><strong>Plage privée de l'hôtel</strong></li>
                    <li><strong>Plage de sable fin</strong></li>
                    <li><strong>Accès plage direct</strong></li>
                    <li><strong>Gardée: </strong> <span>de 09:00h à 18:00h</span></li>
                    <li><strong>Plage aménagée: </strong><span>Parasols && transats</span></li>
                </ul>
            </div>
        </div>
    )
}
