export default function Facilites(){
    return (
        <div className="col-12 col-md-9 about-plage row">
            {/* <img src="/assets/img/about/facilities.jpg" alt="" className="col-12 col-md-4"/> */}
            <div className="row col-12">
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/parking.png" alt=""/>
                        <p>Parking privé</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/spa.png" alt=""/>
                        <p>Centre SPA</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/post.png" alt=""/>
                        <p>Poste</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/shop.png" alt=""/>
                        <p>Boutiques</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/exchange.png" alt=""/>
                        <p>Bureau de change</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/hammam.png" alt=""/>
                        <p>Hammam</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/tv-room.png" alt=""/>
                        <p>Salle de télévision</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/washing-machine.png" alt=""/>
                        <p>Blanchisserie</p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/pressing.png" alt=""/>
                        <p>Service de pressing </p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/car-rental.png" alt=""/>
                        <p>Location de voitures </p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/wifi.png" alt=""/>
                        <p>Espace Wifi  </p>
                    </div>
                </div>
                <div className="col-4">
                    <div className="facility">
                        <img src="/assets/img/about/game-room.png" alt=""/>
                        <p>Salle de jeux </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
