import React, { useEffect, useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import BaseService from '../service/base.service';

const service = new BaseService("Gallery");

function ImageGallery() {
  const [checkedItems, setCheckedItems] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    service.getAll().then(res => setItems(res));
  }, []);

  const toggleItem = (item) => {
    if (checkedItems.includes(item)) {
      setCheckedItems(checkedItems.filter((checkedItem) => checkedItem !== item));
    } else {
      setCheckedItems([...checkedItems, item]);
    }
  };

  const toggleAllItems = () => {
    if (checkedItems.length === items.length) {
      setCheckedItems([]);
    } else {
      setCheckedItems(items);
    }
  };

  const downloadSelectedItems = () => {
    const zip = new JSZip();

    const promises = checkedItems.map((item) => {
      return fetch(item.src)
        .then((response) => response.blob())
        .then((blob) => {
          const filename = item.src.split('/').pop();
          zip.file(filename, blob);
        });
    });

    Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'selected_items.zip');
      });
    });
  };

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|gif)$/.test(url);
  };

  return (
    <div className='container'>
      <h2 className='text-center my-3'>
        Galerie de photos <a href='/' style={{ textDecoration: "underline", color: "#dca73a" }}>Hôtel NESRINE</a>
      </h2>
      <div className="row">
        {items.filter(item => item.display).map((item, index) => (
          <div key={index} className="col-12 col-md-3 border">
            {isImage(item.src) ? (
              <img width={"100%"} height={200} src={item.src} alt={`${index + 1}`} />
            ) : (
              <video width={"100%"} height={200} controls>
                <source src={item.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <input
              className='w-100'
              type='checkbox'
              checked={checkedItems.includes(item)}
              onChange={() => toggleItem(item)}
            />
          </div>
        ))}
      </div>
      <div className='d-flex mt-5 justify-content-end'>
        <button className='btn' onClick={toggleAllItems}>
          {checkedItems.length === items.length ? 'Déselectionner tout' : 'Selectionner tout'}
        </button>
        <button className='btn ml-2' onClick={downloadSelectedItems}>
          Télécharger les éléments sélectionnés
        </button>
      </div>
    </div>
  );
}

export default ImageGallery;
