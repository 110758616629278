import { useEffect } from "react";
import {
  RouterProvider,
  createBrowserRouter
} from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.css";
import Admin from "./admin";
import Gallery from "./admin/modules/Gallery";
import GestionAnimationLoisir from "./admin/modules/animation-loisir";
import ConditionsChambres from "./admin/modules/conditions-chambres";
import Conditions from "./admin/modules/conditions-chambres/conditions";
import GestionLabels from "./admin/modules/conditions-chambres/gestion-labels";
import GestionSupplements from "./admin/modules/conditions-chambres/gestion-supplements";
import MinStay from "./admin/modules/conditions-chambres/min-stay";
import Pax from "./admin/modules/conditions-chambres/pax";
import PrixChambre from "./admin/modules/conditions-chambres/prix-chambre";
import ReleaseDate from "./admin/modules/conditions-chambres/release-date";
import Stock from "./admin/modules/conditions-chambres/stock";
import StopSale from "./admin/modules/conditions-chambres/stop-sale";
import GestionGastronomie from "./admin/modules/gestion-gastronomie";
import GestionInstallationsEtEspaces from "./admin/modules/gestion-installations-espaces";
import GestionSallesConferences from "./admin/modules/gestion-salles-conferences";
import HebergementsAdmin from "./admin/modules/hebergements";
import InformationHotel from "./admin/modules/information-hotel";
import Login from "./admin/modules/login";
import Marche from "./admin/modules/marche";
import Parametres from "./admin/modules/parametres";
import Profil from "./admin/modules/parametres/profil";
import Reservations from "./admin/modules/reservations";
import Situation from "./admin/modules/sitation";
import Client from "./client";
import ConfirmationReservationPaiement from "./client/confirmationReservationPaiement";
import ErreurPaiement from "./client/erreurPaiement";
import ImageGallery from "./client/imageGallery";
import SearchResult from "./client/searchResult";
import GestionPromotions from "./admin/modules/conditions-chambres/gestion-promotion";
import ListePromotions from "./admin/modules/conditions-chambres/gestion-promotion/liste-promotion";
import FormPromotion from "./admin/modules/conditions-chambres/gestion-promotion/form-promotion";
import GestionEvenements from "./admin/modules/gestion-evenements";
import SaintSylvestre from "./client/saintSylvestre";
import ConfirmationPaiementss from "./client/confirmationPaiementss";
import ReservationSS from "./admin/modules/reservation-ss";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Client />,
  },
  {
    path: "/confirmation-paiement-reservation",
    element: <ConfirmationReservationPaiement/>
  },
  {
    path: "/confirmation-paiement-reservation-ss",
    element: <ConfirmationPaiementss/>
  },
  {
    path: "saint-sylvestre",
    element: <SaintSylvestre/>
  },
  {
    path: "/erreur-paiement",
    element: <ErreurPaiement/>
  },
  {
    path: "reservation",
    element: <SearchResult />,
  },
  {
    path: "images",
    element: <ImageGallery />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "information-hotel",
        element: <InformationHotel />,
      },
      {
        path: "situation",
        element: <Situation />,
      },
      {
        path: "hebergements",
        element: <HebergementsAdmin />,
      },
      {
        path: "installations-espaces",
        element: <GestionInstallationsEtEspaces />,
      },
      {
        path: "salles-conferences",
        element: <GestionSallesConferences />,
      },
      {
        path: "gastronomie",
        element: <GestionGastronomie />,
      },
      {
        path: "animation-loisir",
        element: <GestionAnimationLoisir />,
      },
      {
        path: "evenements",
        element: <GestionEvenements />,
      },
      {
        path: "reservation-ss",
        element: <ReservationSS />,
      },
      {
        path: "marches",
        element: <Marche />,
      },
      {
        path: "reservations",
        element: <Reservations />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "conditions-chambres",
        element: <ConditionsChambres />,
        children: [
          {
            path: "conditions",
            element: <Conditions />,
          },
          {
            path: "release-date",
            element: <ReleaseDate />,
          },
          {
            path: "min-stay",
            element: <MinStay />,
          },
          {
            path: "stock",
            element: <Stock />,
          },
          {
            path: "stop-sale",
            element: <StopSale />,
          },
          {
            path: "promo",
            element: <GestionPromotions />,
            children:[
              {
                path: "list",
                element: <ListePromotions/>
              },
              {
                path: "form/:id?",
                element: <FormPromotion/>
              }
            ]
          },
          {
            path: "prix-chambre",
            element: <PrixChambre />,
          },
          {
            path: "prix-chambre-pax",
            element: <Pax />,
          },
          {
            path: "supplements",
            element: <GestionSupplements />,
          },
          {
            path: "labels",
            element: <GestionLabels />,
          },
        ],
      },
      {
        path: "parametres",
        element: <Parametres/>,
        children: [
          {
            path: "profil",
            element: <Profil/>
          }
        ]
      }
    ],
  },
]);
function App() {
  
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      if (
        window.location.origin !== "https://hotel-nesrine.com" &&
        window.location.origin !== "https://hotel-nesrine.com/admin" &&
        window.location.origin !== "https://hotel-nesrine.com/reservation"
      ) {
        window.location.href = "https://hotel-nesrine.com";
      }
    }
  }, []);
 

  return <RouterProvider router={router} />;
}

export default App;
