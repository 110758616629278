import { useEffect, useState } from "react";
import ReservationService from "../../service/reservation.service"
import { choices, formatDateFr } from "../../config/constants";

const service = new ReservationService()
export default function ReservationSS(){
    const [rows, setRows] = useState([]);
    useEffect(() => {
        service.getReservationsSS()
        .then(res => setRows(res))
    }, [])
    return (
        <>
            <h3>Réservations saint sylvestre</h3>
            <table className="table table-sm table-hover table-striped table-bordered">
                <thead className="table-dark">
                        <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Client</th>
                            <th>E-mail</th>
                            <th>Adresse</th>
                            <th>Tél</th>
                            <th>Choix</th>
                            <th>Personnes</th>
                            <th>Montant</th>
                        </tr>
                </thead>

                <tbody>
                    {
                        rows.map(r => (
                            <tr key={r.id}>
                                <td>{r.id}</td>
                                <td>{formatDateFr(r.date)}</td>
                                <td>{r.client}</td>
                                <td>{r.email}</td>
                                <td>{r.adresse}</td>
                                <td>{r.phone}</td>
                                <td>{choices[r.choix-1].label}</td>
                                <td>{r.nb_personnes}</td>
                                <td>{r.total}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
}