import {Fragment} from "react";

export default function About({data}){
    return (
        <Fragment>
            <div className="col-xl-5 col-lg-6">
                <div className="customer-img mb-120">
                    <img src="assets/img/about/about.jpeg" className="customar-img1" alt=""/>
                    <img src="assets/img/customer/customar2.png" className="customar-img2" alt=""/>
                    <div className="service-experience heartbeat">
                        <h3>Soyez les bienvenus</h3>
                    </div>
                </div>
            </div>
            <div className=" col-xl-4 col-lg-4">
                <div className="customer-caption">
                    <span>À propos de Hôtel Nesrine</span>
                    <h2>{data.label}</h2>
                    <div className="caption-details">
                        <p className="pera-dtails">{data.sous_label} </p>
                        <p>{data.presentation}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
