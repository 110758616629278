import { useEffect, useMemo, useState } from "react";
import PromotionService from "../../../../service/promotion.service";
import { BiSave, BiTrash } from "react-icons/bi";
import { useParams } from "react-router-dom";

const service = new PromotionService();

const PROMOTION_IS = {
  label: "",
  etat: 1,
  categorie: "",
  sous_categorie: "",
  date_debut_sejour: "",
  date_fin_sejour: "",
  date_debut_reservation: "",
  date_fin_reservation: "",
  min_stay: 0,
  max_stay: 0,
  marches_ids: [],
};
const PROMOTION_REDUCTION_IS = {
  id_promotion: null,
  nature_reduction: "",
  lpd: "",
  dp: "",
  pc: "",
  ai_soft: "",
  ai: "",
  chambres_ids: [],
  added_values_ids: [],
  supplements: [],
};
const PROMOTION_CONDITIONS_IS = {
  id_promotion: null,
  reduction_3lit: "",
  reduction_4lit: "",
  etiquette: "",
  conditions: [],
};

const ConditionsForm = ({ id_promotion, data, setData, sousCategories }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formAdEnf, setFormAdEnf] = useState({ ad: "", enf: "" });
  const [promotionConditions, setPromotionConditions] = useState(data);
  const handleChangePromotionConditions = (e) => {
    const { name, value } = e.target;
    setPromotionConditions({ ...promotionConditions, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    service
      .savePromotionConditions({
        ...promotionConditions,
        conditions: JSON.stringify(promotionConditions.conditions),
        id_promotion,
      })
      .then((res) => {
        setPromotionConditions({ ...promotionConditions, id: res.id });
        setData({ ...promotionConditions, id: res.id });
      })
      .catch((error) => {
        setError(error.response.data);
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const addAdsEnfs = () => {
    const index = promotionConditions.conditions.findIndex(
      (c) => c.ad === formAdEnf.ad && c.enf === formAdEnf.enf
    );
    if (index !== -1) return alert("Ligne dèja ajoutée");
    setPromotionConditions({
      ...promotionConditions,
      conditions: [
        ...promotionConditions.conditions,
        { ...formAdEnf, reduction: "", min_age: "", max_age: "" },
      ],
    });
  };

  const deleteAdsEnfs = (index) => {
    setPromotionConditions(
      {
        ...promotionConditions,
        conditions: promotionConditions.conditions.filter((c, i) => i !== index)
      }
    )
  };
  const handleChangeAdsEnfs = (index, field, value) => {
    let newArray = [...promotionConditions.conditions];
    newArray[index][field] = value;
    setPromotionConditions({...promotionConditions, conditions: newArray});
  };

  if (loading) return <>Chargement...</>;
  if (error) return <>{JSON.stringify(error)}</>;
  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-12 col-md-5">
          <div className="form-group">
            <label htmlFor="select-sous-categorie">Étiquette</label>
            <select
              name="etiquette"
              id="select-sous-categorie"
              value={promotionConditions.etiquette}
              onChange={handleChangePromotionConditions}
              className="form-control"
            >
              <option value="">-- Veuillez selectionner --</option>
              {sousCategories.map((sc) => (
                <option key={sc.id}>{sc.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="reduction_3lit">Réd% 3ème lit</label>
            <input
              id="reduction_3lit"
              type="text"
              className="form-control"
              name="reduction_3lit"
              value={promotionConditions.reduction_3lit}
              onChange={handleChangePromotionConditions}
            />
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="form-group">
            <label htmlFor="reduction_4lit">Réd% 4ème lit</label>
            <input
              id="reduction_4lit"
              type="text"
              className="form-control"
              name="reduction_4lit"
              value={promotionConditions.reduction_4lit}
              onChange={handleChangePromotionConditions}
            />
          </div>
        </div>
      </div>
      <h4 className="border-bottom">Adultes + enfants</h4>
      <div id="form-ad-enf" className="mt-2">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="adultes">Adultes</label>
              <select
                value={formAdEnf.ad}
                onChange={(e) =>
                  setFormAdEnf({ ...formAdEnf, ad: e.target.value })
                }
                name="adultes"
                id="adultes"
                className="form-control"
              >
                <option value="">-- Ad --</option>
                <option value="0">0 adultes</option>
                <option value="1">1 adulte</option>
                <option value="2">2 adultes</option>
                <option value="3">3 adultes</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label htmlFor="enfants">Enfants</label>
              <select
                value={formAdEnf.enf}
                onChange={(e) =>
                  setFormAdEnf({ ...formAdEnf, enf: e.target.value })
                }
                name="enfants"
                id="enfants"
                className="form-control"
              >
                <option value="">-- Enf --</option>
                {[1, 2, 3, 4]
                  .filter((n) => {
                    return n + Number(formAdEnf.ad) <= 4;
                  })
                  .map((n) => {
                    return (
                      <option key={n} value={n}>
                        Enfant {n}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-12 col-md-2">
            <div className="form-group">
              <label htmlFor="validation">Ajouter</label>
              <button
                type="button"
                onClick={addAdsEnfs}
                disabled={formAdEnf.ad === "" || formAdEnf.enf === ""}
                id="ajouter"
                className="w-100 p-1"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-2">
        {promotionConditions.conditions.map((c, index) => (
          <div className="row border-bottom pb-2" key={index}>
            <div className="col-12 col-md-4">
              <small style={{ textDecoration: "underline" }}>
                {c.ad} adulte(s) - enfant {c.enf} :
              </small>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group">
                <label htmlFor={`reduction-${index}`}>Réd%</label>
                <input
                  name="reduction"
                  onChange={(e) =>
                    handleChangeAdsEnfs(index, e.target.name, e.target.value)
                  }
                  type="text"
                  className="form-control"
                  id={`reduction-${index}`}
                  value={c.reduction}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label htmlFor={`min-age-${index}`}>Min age</label>
                <input
                  name="min_age"
                  onChange={(e) =>
                    handleChangeAdsEnfs(index, e.target.name, e.target.value)
                  }
                  type="text"
                  className="form-control"
                  id={`min-age-${index}`}
                  value={c.min_age}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label htmlFor={`max-age-${index}`}>Max age</label>
                <input
                  name="max_age"
                  onChange={(e) =>
                    handleChangeAdsEnfs(index, e.target.name, e.target.value)
                  }
                  type="text"
                  className="form-control"
                  id={`max-age-${index}`}
                  value={c.max_age}
                />
              </div>
            </div>

            <div className="col-12 col-md-1">
              <span
                style={{ cursor: "pointer", fontSize: 24 }}
                type="button"
                onClick={() => deleteAdsEnfs(index)}
              >
                x
              </span>
            </div>
          </div>
        ))}
      </div>

      <button disabled={id_promotion === undefined} className="mt-3">
        <BiSave />
        Enregistrer Conditions de chambres
      </button>
    </form>
  );
};

const ReductionForm = ({
  id_promotion,
  supplements,
  chambres,
  addedValues,
  data,
  setData,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [promotionReduction, setPromotionReduction] = useState(data);
  const [addedValuesSelectValue] = useState("");
  const handleChangePromotionReduction = (e) => {
    const { name, value } = e.target;
    setPromotionReduction({ ...promotionReduction, [name]: value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    service
      .savePromotionReduction({
        ...promotionReduction,
        chambres_ids: JSON.stringify(promotionReduction.chambres_ids),
        added_values_ids: JSON.stringify(promotionReduction.added_values_ids),
        supplements: JSON.stringify(promotionReduction.supplements),
        id_promotion,
      })
      .then((res) => {
        setPromotionReduction({ ...promotionReduction, id: res.id });
        setData({ ...promotionReduction, id: res.id });
      })
      .catch((error) => {
        setError(error.response.data);
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const handleSupplementChange = (e, id_hebergement) => {
    let { name, value } = e.target;
    value = Number(value);
    const index = promotionReduction.supplements.findIndex(
      (s) => s.label === name
    );
    let newArray = [...promotionReduction.supplements];
    if (index === -1) newArray = [...newArray, { label: name, valeur: value, id_hebergement }];
    else newArray[index].valeur = value;
    setPromotionReduction({ ...promotionReduction, supplements: newArray });
  };

  const handleChambreSelection = (id) => {
    const index = promotionReduction.chambres_ids.findIndex(
      (chId) => chId === id
    );
    if (index === -1)
      setPromotionReduction({
        ...promotionReduction,
        chambres_ids: [...promotionReduction.chambres_ids, id],
      });
    else
      setPromotionReduction({
        ...promotionReduction,
        chambres_ids: promotionReduction.chambres_ids.filter(
          (chId) => chId !== id
        ),
      });
  };

  const handleAddedValueSelection = (id) => {
    const index = promotionReduction.added_values_ids.findIndex(
      (avId) => avId === id
    );
    if (index === -1)
      setPromotionReduction({
        ...promotionReduction,
        added_values_ids: [...promotionReduction.added_values_ids, id],
      });
    else
      setPromotionReduction({
        ...promotionReduction,
        added_values_ids: promotionReduction.added_values_ids.filter(
          (avId) => avId !== id
        ),
      });
  };

  if (loading) return <>Chargement...</>;
  if (error) return <>{JSON.stringify(error)}</>;
  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="nature_reduction">Nature Réduction</label>
            <select
              name="nature_reduction"
              id="nature_reduction"
              className="form-control"
              onChange={handleChangePromotionReduction}
              value={promotionReduction.nature_reduction}
            >
              <option value="">-- --</option>
              <option value="pourcentage">%age</option>
              <option value="reduction">Valeur</option>
            </select>
          </div>
        </div>
        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="lpd">lpd</label>
            <input
              type="text"
              className="form-control"
              id="lpd"
              name="lpd"
              value={promotionReduction.lpd}
              onChange={handleChangePromotionReduction}
            />
          </div>
        </div>
        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="dp">dp</label>
            <input
              type="text"
              className="form-control"
              id="dp"
              name="dp"
              value={promotionReduction.dp}
              onChange={handleChangePromotionReduction}
            />
          </div>
        </div>
        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="pc">pc</label>
            <input
              type="text"
              className="form-control"
              id="pc"
              name="pc"
              value={promotionReduction.pc}
              onChange={handleChangePromotionReduction}
            />
          </div>
        </div>
        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="ai_soft">ai_soft</label>
            <input
              type="text"
              className="form-control"
              id="ai_soft"
              name="ai_soft"
              value={promotionReduction.ai_soft}
              onChange={handleChangePromotionReduction}
            />
          </div>
        </div>
        <div className="col-12 col-md-2">
          <div className="form-group">
            <label htmlFor="ai">ai</label>
            <input
              type="text"
              className="form-control"
              id="ai"
              name="ai"
              value={promotionReduction.ai}
              onChange={handleChangePromotionReduction}
            />
          </div>
        </div>
        <div className="col-12">
          <h4 className="border-bottom">Suppléments</h4>
        </div>
        {supplements.map((s) => {
          const index = promotionReduction.supplements.findIndex(
            (prs) => prs.label === s.label
          );
          const value =
            index === -1 ? 0 : promotionReduction.supplements[index].valeur;
          return (
            <div className="col-12 col-md-4" key={s.id}>
              <div className="form-group">
                <label htmlFor={`input-supplement-${s.id}`}>{s.label}</label>
                <input
                  onClick={(e) => e.target.select()}
                  value={value}
                  onChange={e => handleSupplementChange(e, s.id_hebergement)}
                  name={`${s.label}`}
                  type="text"
                  className="form-control"
                  id={`input-supplement-${s.id}`}
                />
              </div>
            </div>
          );
        })}
        <div className="col-12">
          <h4 className="border-bottom">Chambres</h4>
        </div>
        {chambres.map((ch) => (
          <div className="col-12 col-md-5" key={ch.id}>
            <div className="form-group">
              <input
                id={`input-chambre-${ch.id}`}
                onChange={() => handleChambreSelection(ch.id)}
                type="checkbox"
                checked={promotionReduction.chambres_ids.includes(ch.id)}
              />
              <label htmlFor={`input-chambre-${ch.id}`}>{ch.categorie}</label>
            </div>
          </div>
        ))}
        <div className="col-12">
          <div className="d-flex border-bottom">
            <h4 style={{ flex: 1 }}>Added values</h4>
            <select
              value={addedValuesSelectValue}
              onChange={(e) =>
                handleAddedValueSelection(Number(e.target.value))
              }
              style={{ flex: 1 }}
              className="form-control"
            >
              <option value="">-- Selectionner pour ajouter --</option>
              {addedValues.map((av) => (
                <option key={av.id} value={av.id}>
                  {av.label}
                </option>
              ))}
            </select>
          </div>

          <div>
            {addedValues
              .filter((av) =>
                promotionReduction.added_values_ids.includes(av.id)
              )
              .map((av) => (
                <p key={av.id}>
                  {av.label}{" "}
                  <button onClick={() => handleAddedValueSelection(av.id)}>
                    <BiTrash />
                  </button>
                </p>
              ))}
          </div>
        </div>
      </div>
      <button disabled={id_promotion === undefined} className="my-3">
        <BiSave />
        Enregistrer Réductions chambres
      </button>
    </form>
  );
};
export default function FormPromotion() {
  const [promotion, setPromotion] = useState(PROMOTION_IS);
  const { id } = useParams();
  const [promotionData, setPromotionData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [promotionReductionInParent, setPromotionReductionInParent] =
    useState();
  const [conditonsChambresInParent, setConditonsChambresInParent] = useState();
  useEffect(() => {
    service
      .getData()
      .then((res) => {
        setPromotionData(res);
      })
      .catch((error) => setError(error.response.data));
  }, []);
  useEffect(() => {
    getPromotionForUpdate(id);
  }, [id]);

  const getPromotionForUpdate = (id) => {
    if (id) {
      service
        .getPromotionForUpdate(id)
        .then((res) => {
          if (res.promotion) {
            setPromotion({
              ...res.promotion,
              marches_ids: JSON.parse(res.promotion.marches_ids),
            });
            if (res.promotionConditions)
              setConditonsChambresInParent({
                ...res.promotionConditions,
                conditions: JSON.parse(res.promotionConditions.conditions),
              });
            else setConditonsChambresInParent(PROMOTION_CONDITIONS_IS);

            if (res.promotionReduction)
              setPromotionReductionInParent({
                ...res.promotionReduction,
                chambres_ids: JSON.parse(res.promotionReduction.chambres_ids),
                added_values_ids: JSON.parse(
                  res.promotionReduction.added_values_ids
                ),
                supplements: JSON.parse(res.promotionReduction.supplements),
              });
            else setPromotionReductionInParent(PROMOTION_REDUCTION_IS);
          }
        })
        .catch((error) => setError(error));
    } else {
      setPromotion(PROMOTION_IS);
      setConditonsChambresInParent(PROMOTION_CONDITIONS_IS);
      setPromotionReductionInParent(PROMOTION_REDUCTION_IS);
    }
  };

  const handleChangePromotion = (e) => {
    const { name, value } = e.target;
    setPromotion({ ...promotion, [name]: value });
  };

  const handleMarcheChecked = (id) => {
    let newArray = [...promotion.marches_ids];
    const index = newArray.findIndex((o) => o === id);
    if (index === -1) newArray.push(id);
    else newArray = newArray.filter((o) => o !== id);
    setPromotion({ ...promotion, marches_ids: newArray });
  };

  const submitPromotion = (e) => {
    setLoading(true);
    e.preventDefault();
    const object = {
      ...promotion,
      marches_ids: JSON.stringify(promotion.marches_ids),
      categorie: "",
    };
    const action = promotion.id
      ? service.updatePromotion(object)
      : service.createPromotion(object);
    action
      .then((res) => {
        if (!promotion.id) setPromotion(res);
      })
      .catch((error) => {
        console.error(error);
        setError(error.response.data);
      })
      .finally(() => setLoading(false));
  };

  const activeForm = useMemo(() => {
    if(!promotion.id) return <></>
    if (promotion.categorie === "conditions") {
      return (
        <ConditionsForm
          id_promotion={promotion.id}
          data={conditonsChambresInParent}
          setData={setConditonsChambresInParent}
          sousCategories={promotionData.sousCategories}
        />
      );
    } else if (promotion.categorie === "reduction") {
      return (
        <ReductionForm
          id_promotion={promotion.id}
          supplements={promotionData.supplements}
          chambres={promotionData.chambres}
          addedValues={promotionData.addedValues}
          data={promotionReductionInParent}
          setData={setPromotionReductionInParent}
        />
      );
    }
  }, [promotion.categorie, promotion.id]);

  if (!promotionData || loading) return <>chargement...</>;
  if (error) return <>{JSON.stringify(error)}</>;

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-12 col-md-7 border-right">
          <h4 className="border-bottom">Détails promotion</h4>
          <form onSubmit={submitPromotion}>
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="select-etat">État</label>
                  <select
                    name="etat"
                    id="select-etat"
                    value={promotion.etat}
                    onChange={handleChangePromotion}
                    className="form-control"
                  >
                    <option value="1">Acivée</option>
                    <option value="0">Désactivée</option>
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label htmlFor="input-label">Libellé</label>
                  <input
                    name="label"
                    id="input-label"
                    value={promotion.label}
                    onChange={handleChangePromotion}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-12 col-md-3">
                <label htmlFor="input-date-debut-sejour">
                  Date début séjour
                </label>
                <input
                  value={promotion.date_debut_sejour}
                  onChange={handleChangePromotion}
                  type="date"
                  className="form-control"
                  id="input-date-debut-sejour"
                  name="date_debut_sejour"
                />
              </div>
              <div className="col-12 col-md-3">
                <label htmlFor="input-date-fin-sejour">Date fin séjour</label>
                <input
                  value={promotion.date_fin_sejour}
                  onChange={handleChangePromotion}
                  type="date"
                  className="form-control"
                  id="input-date-fin-sejour"
                  name="date_fin_sejour"
                />
              </div>
              <div className="col-12 col-md-3">
                <label htmlFor="input-date-debut-reservation">
                  Date début réservation
                </label>
                <input
                  value={promotion.date_debut_reservation}
                  onChange={handleChangePromotion}
                  type="date"
                  className="form-control"
                  id="input-date-debut-reservation"
                  name="date_debut_reservation"
                />
              </div>
              <div className="col-12 col-md-3">
                <label htmlFor="input-date-fin-reservation">
                  Date fin réservation
                </label>
                <input
                  value={promotion.date_fin_reservation}
                  onChange={handleChangePromotion}
                  type="date"
                  className="form-control"
                  id="input-date-fin-reservation"
                  name="date_fin_reservation"
                />
              </div>
              <div className="col-12 col-md-3">
                <label htmlFor="input-min-stay">
                  Min stay
                </label>
                <input
                  value={promotion.min_stay}
                  onChange={handleChangePromotion}
                  type="number"
                  className="form-control"
                  id="input-min-stay"
                  name="min_stay"
                />
              </div>
              <div className="col-12 col-md-3">
                <label htmlFor="input-max-stay">
                  Max stay
                </label>
                <input
                  value={promotion.max_stay}
                  onChange={handleChangePromotion}
                  type="number"
                  className="form-control"
                  id="input-max-stay"
                  name="max_stay"
                />
              </div>
              <div className="col-12 mt-2">
                <div className="form-group d-flex flex-wrap">
                  <label style={{ minWidth: "100%" }}>Marchés</label>
                  {promotionData.marches.map((m) => (
                    <div className="ml-2" key={m.id}>
                      <input
                        onChange={(_) => handleMarcheChecked(m.id)}
                        checked={promotion.marches_ids.includes(m.id)}
                        type="checkbox"
                        id={`checkbox-marche-${m.id}`}
                      />
                      <label htmlFor={`checkbox-marche-${m.id}`}>
                        {m.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <button className="w-100">
                  <BiSave />
                  Enregistrer promotion
                </button>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <select
                    name="categorie"
                    id="select-categorie"
                    value={promotion.categorie}
                    onChange={handleChangePromotion}
                    className="form-control"
                  >
                    <option value="">-- Catégorie</option>
                    <option value="reduction">Réduction</option>
                    <option value="conditions">Conditions de chambres</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>

        {(promotion.categorie && promotion.id) && (
          <div className="col-12 col-md-5">
            <h4 className="border-bottom">{promotion.categorie}</h4>
            {activeForm}
          </div>
        )}
      </div>
    </div>
  );
}
