import AdminPageTitle from "../../components/admin-page-title";
import {useEffect, useState} from "react";
import BaseService from "../../service/base.service";
import {BiEdit, BiPlus, BiSave} from "react-icons/bi";
import {CgClose} from "react-icons/cg";
import {ImImage} from "react-icons/im";
import {FiDelete} from "react-icons/fi";
import FileUploader from "../../components/file-uploader";
import HebergementService from "../../service/hebergement.service";
import LoadingComponent from "../../components/loading-component";

const service = new HebergementService()
const imagesService = new BaseService("ImageHebergement")
const FORM_IS = {
    id: null,
    categorie: "",
    nombre: 0,
    superficie: "",
    min:0,
    max:0
}
export default function HebergementsAdmin(){
    const [rows, setRows] = useState([]);
    const [form, setForm] = useState(FORM_IS);
    const [images, setImages] = useState([]);
    const [rowForImages, setRowForImages] = useState({});
    const [repartitions, setRepartitions] = useState(null);
    const [rowForAccessories, setRowForAccessories] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getAll()
    }, [])


    const getAll = () => {
        setLoading(true)
        service.getAll()
            .then(rows => {
                setRows(rows)
                setLoading(false)
            })
            .catch(error => console.log(error))
    }

    const submit = e => {
        
        setLoading(true)
        e.preventDefault()
        if(!form.id) postEntity()
        else putEntity()

    }

    const postEntity = () => {
        service.create(form)
            .then(res => {
                if(res) resetForm()
                
        setLoading(false)
            })
            .catch(error => console.log(error))
    }
    const putEntity = () => {
        service.save(form.id, form)
            .then(res => {
                if(res) resetForm()
                
        setLoading(false)
            })
            .catch(error => console.log(error))
    }


    const deleteEntity = id => {
        service.delete(id)
            .then(res => {
                if(res) resetForm(false)
            })
            .catch(error => console.log(error))
    }

    const resetForm = (reset = true) => {
        if(reset)
            setForm(FORM_IS)
        getAll()
    }

    const onEditClicked = row => {
        setForm(row)
        focusFirstInput()
    }

    const focusFirstInput = () => {
        document.getElementById("input-categorie").select()
    }

    const loadImages = row => {
        setRowForImages(row)
        imagesService.findBy("id_hebergement", row.id)
            .then(rows => setImages(rows))
    }

    const postImage = (url) => {
        
        setLoading(true)
        imagesService.create({image: url, id_hebergement: rowForImages.id})
            .then(res => {
                if(res){
                    loadImages(rowForImages)
                    
        setLoading(false)
                }
            })
    }
    const deleteImage = id => {
        imagesService.delete(id)
            .then(res => {
                if(res){
                    loadImages(rowForImages)
                }
            })
    }

    const updateRepartition = (index,field,value) => {
        let repartitionsWU = [...repartitions]
        repartitionsWU[index][field] = value
        setRepartitions(repartitionsWU)
    }

    const saveRepartitions = () => {
        service
            .saveRepartitions(repartitions)
            .then(res => console.log(res))
    }
    const addAccessory = e => {
        let accessory = {}
        switch (e.target.value){
            case "1":
                accessory = {image: '/assets/img/ac.png', label: "Climatisation"}
                break;
            case "2":
                accessory = {image: '/assets/img/balcony.png', label: "Blacon"}
                break;
            case "3":
                accessory = {image: '/assets/img/bathroom.png', label: "Douche"}
                break;
            case "4":
                accessory = {image: '/assets/img/beach.png', label: "Vue mer"}
                break;
            case "5":
                accessory = {image: '/assets/img/pool.png', label: "Vue piscine"}
                break;
            case "6":
                accessory = {image: '/assets/img/minibar.png', label: "Mini bar"}
                break;
            case "7":
                accessory = {image: '/assets/img/phone.png', label: "Téléphone"}
                break;
            case "8":
                accessory = {image: '/assets/img/tv.png', label: "TV"}
                break;
            case "9":
                accessory = {image: '/assets/img/surface.png', label: "Superficie"}
                break;
            default: accessory = {}
        }
        actionAccessory("add", accessory)
    }
    const actionAccessory = (action, payload) => {
        if(action === "add"){
            service
                .actionAccessory("add", {...payload, id_hebergement: rowForAccessories.id})
                    .then(res => {
                        setRowForAccessories({...rowForAccessories, room_accessories: [...rowForAccessories.room_accessories, res]})
                        const index = rows.findIndex(r => r.id === rowForAccessories.id)
                        let rowsWU = [...rows]
                        rowsWU[index].room_accessories = [...rowsWU[index].room_accessories, res]
                        setRows(rowsWU)
                    })
        }
        if(action === "delete"){
            service
                .actionAccessory("delete", payload)
                    .then(res => {
                        setRowForAccessories({...rowForAccessories, room_accessories: rowForAccessories.room_accessories.filter(ra => ra.id !== payload)})
                        const index = rows.findIndex(r => r.id === rowForAccessories.id)
                        let rowsWU = [...rows]
                        rowsWU[index].room_accessories = rowsWU[index].room_accessories.filter(ra => ra.id !== payload)
                        setRows(rowsWU)
                    })
        }
    }

    return (
        <div>
            <AdminPageTitle title={"Hébérgements"}/>
            <div>
                <form onSubmit={submit} className={"row"}>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label htmlFor="input-emplacement">Catégorie</label>
                            <input required value={form.categorie} onChange={e => setForm({...form, categorie: e.target.value})} type="text" className={"form-control"} id={"input-categorie"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-1">
                        <div className="form-group">
                            <label htmlFor="input-nombre">Nombre</label>
                            <input value={form.nombre} onChange={e => setForm({...form, nombre:Number(e.target.value)})} type="number" step={1} className={"form-control"} id={"input-nombre"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-1">
                        <div className="form-group">
                            <label htmlFor="input-superficie">Superficie</label>
                            <input value={form.superficie} onChange={e => setForm({...form, superficie:e.target.value})} type="text" className={"form-control"} id={"input-superficie"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-1">
                        <div className="form-group">
                            <label htmlFor="input-min">min</label>
                            <input value={form.min} onChange={e => setForm({...form, min:e.target.value})} type="number" step={1} className={"form-control"} id={"input-min"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-1">
                        <div className="form-group">
                            <label htmlFor="input-max">max</label>
                            <input value={form.max} onChange={e => setForm({...form, max:e.target.value})} type="number" step={1} className={"form-control"} id={"input-max"}/>
                        </div>
                    </div>
                    <div className="col-12 col-md-3  d-flex">
                        <div className="form-group d-flex align-items-end">
                            {
                                form.id ?
                                    <div className={"d-flex"}>
                                        <button type={"submit"} className={"btn btn-warning"}><BiSave/> Enregistrer</button>
                                        <button onClick={resetForm} className={"btn btn-error"}><CgClose/></button>
                                    </div>
                                    :
                                    <button type={"submit"} className={"btn btn-success"}><BiPlus/> Ajouter un emplacement</button>
                            }
                        </div>
                    </div>

                </form>
                <table className={"mt-2 table table-bordered table-hover table-sm"}>
                    <thead className={"table-dark"}>
                    <tr>
                        <th width={"5%"}>#</th>
                        <th width={"45%"}>Catégorie</th>
                        <th width={"10%"}>Nombre</th>
                        <th width={"10%"}>Superficie</th>
                        <th width={"10%"}>nb_personnes</th>
                        <th width={"30%"}>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rows.map((row,i) => (
                            <tr key={row.id}>
                                <td>{row.id}</td>
                                <td>{row.categorie}</td>
                                <td>{row.nombre}</td>
                                <td>{row.superficie}</td>
                                <td>{row.nb_personnes}</td>
                                <td className={"d-flex justify-content-around h-actions"}>
                                    <button className={"btn-danger btn-sm"} onClick={() => deleteEntity(row.id)}>supp.</button>
                                    <button className={"btn-success"} onClick={() => {
                                        setRepartitions(row.repartition_hebergements)
                                        setRowForAccessories(null)
                                    }}>Rép</button>
                                    <button className={"btn-info"} onClick={() => {
                                        setRepartitions(null)
                                        setRowForAccessories(row)
                                    }}>Acc.</button>
                                    {/*<button>Marchés</button>*/}
                                    <button className={"btn-warning btn-sm"} onClick={() => onEditClicked(row)}>modif.</button>
                                    <button className={"btn-primary btn-sm"} onClick={() => loadImages(row)}  data-toggle="modal" data-target="#exampleModal">Images.</button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>


                {
                    repartitions &&
                       <div className={"col-md-4 col-12"}>
                           <div className="d-flex justify-content-between">
                               <h3>Répartition</h3>
                               <button className={"btn btn-danger"} onClick={() => setRepartitions(null)}>x</button>
                           </div>
                           <table className={"table table-striped table-bordered table-prix-chambre"}>
                               <thead>
                               <tr>
                                   <th>#</th>
                                   <th>nb_adultes</th>
                                   <th>nb_enfants</th>
                                   <th>max_bebes</th>
                                   <th>actif</th>
                               </tr>
                               </thead>
                               <tbody>
                               {
                                   repartitions.map((r,i) =>
                                       <tr key={i}>
                                           <td>{i+1}</td>
                                           <td>{r.nb_adulte}</td>
                                           <td>{r.nb_enfant}</td>
                                           <td><input type="number" value={r.nb_bebe} onChange={e => updateRepartition(i, "nb_bebe", e.target.value)}/></td>
                                           <td><input type="checkbox" checked={r.actif} onChange={e => updateRepartition(i, "actif", e.target.checked)}/></td>
                                       </tr>
                                   )
                               }
                               </tbody>
                           </table>
                           <button className={"btn btn-success"} style={{float: "right"}} onClick={saveRepartitions}>Enregistrer</button>
                       </div>
                }
                {
                    rowForAccessories &&
                    <div className="col-12 ps-5">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3>Accessoires {rowForAccessories.categorie}</h3>
                            <select className={"form-select"} onChange={addAccessory}>
                                <option value="">--Selectionner pour ajouter--</option>
                                <option value="1">Climatiseur</option>
                                <option value="2">Balcon</option>
                                <option value="3">Douche</option>
                                <option value="4">Vue mer</option>
                                <option value="5">Vue piscine</option>
                                <option value="6">Minibar</option>
                                <option value="7">Téléphone</option>
                                <option value="8">TV</option>
                                <option value="9">Superficie</option>
                            </select>
                            <button onClick={() => setRowForAccessories(null)} className={"btn btn-danger"}>x</button>
                        </div>
                        <table className={"table table-hover table-striped table-bordered"}>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>image</th>
                                <th>libellé</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                rowForAccessories.room_accessories.map((a,i) => (
                                    <tr key={a.id}>
                                        <td>{i+1}</td>
                                        <td><img src={a.image} height={30} alt=""/></td>
                                        <td>{a.label}</td>
                                        <td><button onClick={() => actionAccessory("delete", a.id)}>supprimer</button></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                }
            </div>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Images hébérgement {rowForImages.categorie}</h1>
                            <button type="button" className="close"  data-dismiss="modal" aria-label="Close">
                                <i className="ti-close"/>
                                </button>
                        </div>
                        <div className="modal-body d-flex justify-content-around flex-wrap">
                            {
                                images.map(i => (
                                    <div key={i.id} style={{height: 150, width: 150, position: "relative"}}>
                                        <img height={"100%"} width={"100%"} src={i.image} alt="img"/>
                                        <button onClick={() => deleteImage(i.id)} className={"btn btn-danger"} style={{position: "absolute", top: 8, right: 8}}>x</button>
                                    </div>
                                ))
                            }
                            <FileUploader html={<label htmlFor={"image-hebergement"} className={"border d-flex justify-content-center align-items-center"} style={{height: 150, width: 150}}><BiPlus/></label>} id={"image-hebergement"} onUrlReceived={postImage}/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Fermer</button>
                            {/* <button type="button" className="btn btn-primary">Enregistrer</button> */}
                        </div>
                    </div>
                </div>
            </div>
            {loading && <LoadingComponent/>}
        </div>
    )
}
