import { Fragment } from "react";
import Slider from "react-slick";

export default function Gallery({ data }) {
  
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    adaptativeHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="col-12 col-md-9 about-gallery ml-2 mb-3">
      <Slider {...settings}>
        {data
          .filter((image) => image.display)
          .map((image, index) => {
            if (index < 9) {
              return (
                <div key={index} className="p-1">
                  <img src={image.src} alt="" width={"100%"} />
                </div>
              );
            }
          })}
      </Slider>
    </div>
  );
  return (
    <Fragment>
      <div className="col-12 col-md-9 about-gallery ml-2">
        <div className="row">
          {data
            .filter((image) => image.display)
            .map((image, index) => {
              if (index < 9) {
                return (
                  <div key={index} className="col-12 col-md-4">
                    <img src={image.src} alt="" />
                  </div>
                );
              }
            })}
        </div>
        <div
          className="float-right mt-2"
          style={{ textDecoration: "underline" }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href={"/images"}
          >{`Voir tous et téléchargement-->`}</a>
        </div>
      </div>
    </Fragment>
  );
}
