import { useEffect, useState } from "react";
import AboutSwitcher from "../about-sections";
import Header from "./header";
import BaseService from "../service/base.service";
import { Link, useNavigate } from "react-router-dom";
import Contact from "./contact";
const service = new BaseService("InformationHotel");
function Client() {
  const [currentAboutPage, setCurrentAboutPage] = useState("about");
  const [data, setData] = useState(null);
  useEffect(() => {
    service.getLandingData().then((res) => setData(res));
  }, []);

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  if (!data) return <p>chargement...</p>;
  return (
    <>
      <div className="topbar">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <span>
                <i style={{ color: "#dca73a" }} className={"bi bi-telephone"} />{" "}
                {data.informationsHotel.tel1}
              </span>
              <span className={"ml-2"}>
                <i style={{ color: "#dca73a" }} className={"bi bi-envelope"} />{" "}
                {data.informationsHotel.email1}
              </span>
            </div>
            <div className="col-6 text-right social-icons">
              <Link
                target="_blank"
                rel="noreferrer"
                to={data.informationsHotel.facebook}
              >
                <i className={"bi bi-facebook"} />
              </Link>
              <Link
                target="_blank"
                rel="noreferrer"
                to={data.informationsHotel.instagram}
              >
                <i className={"bi bi-instagram ml-3"} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <header>
        <Header onSSClicked={handleOpen} />
      </header>

      <main>
        {showModal && (
          <div className="modal-overlay">
            <div className="ss-modal">
              <div className="ss-modal-body">
                <img
                  src="/assets/img/saint-sylvestre-2.jpg"
                  alt=""
                  width="100%"
                  height="auto"
                />
              </div>
              <div className="ss-buttons">
                <button onClick={handleClose} className="ss-fermer btn">
                  Fermer
                </button>
                <button
                  onClick={() => navigate("saint-sylvestre")}
                  className="glow-text ss-reserver btn ml-2"
                >
                  Réserver
                </button>
              </div>
            </div>
          </div>
        )}

        <div id={"hero"} className="slider-area">
          <div className="slider-active dot-style">
            <div
              style={{
                background: `url("${data.informationsHotel.image_acceuil}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className="hero-overly slider-height d-flex align-items-center"
            >
              <div className="container">
                <div className="row justify-content-center text-center">
                  <div className="col-xl-9">
                    <div className="h1-slider-caption">
                      <h1 data-animation="fadeInUp" data-delay=".4s">
                        Hotel Nesrine Hammamet
                      </h1>
                      <h3 data-animation="fadeInDown" data-delay=".4s">
                        Avenue De La Paix, 8050 Hammamet, Tunisia
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="booking-area">
          <div className="container">
            <div className="row ">
              <div className="col-12">
                <div
                  style={{
                    background: "white",
                    padding: 16,
                    borderRadius: 16,
                    boxShadow: "1px 1px 5px #eee",
                  }}
                >
                  <SearchForm />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <section
          style={{ paddingTop: 160 }}
          id={"about"}
          className="make-customer-area customar-padding fix"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="font-back-tittle mb-45">
                  <div className="archivment-front">
                    <h3>À propos</h3>
                  </div>
                  <h3 className="archivment-back">Hôtel Nesrine Hammamet</h3>
                </div>
              </div>
              <AboutSwitcher
                current={currentAboutPage}
                data={data.informationsHotel}
                images={data.images}
              />
              <div className="col-xl-2 col-lg-2 col-md-2 about-buttons">
                <div className="row">
                  <div className="col-3 col-md-12">
                    <button
                      onClick={() => setCurrentAboutPage("about")}
                      className={`btn btn1 ${
                        currentAboutPage === "about" && "about-btn-active"
                      }`}
                    >
                      <img src="/assets/img/about/about.png" alt="" />
                      <span className="d-none d-md-inline">À propos</span>
                    </button>
                  </div>
                  <div className="col-3 col-md-12">
                    <button
                      onClick={() => setCurrentAboutPage("plage")}
                      className={`btn btn1 ${
                        currentAboutPage === "plage" && "about-btn-active"
                      }`}
                    >
                      <img src="/assets/img/about/plage.png" alt="" />
                      <span className="d-none d-md-inline">La Plage</span>
                    </button>
                  </div>
                  <div className="col-3 col-md-12">
                    <button
                      onClick={() => setCurrentAboutPage("facilities")}
                      className={`btn btn1 ${
                        currentAboutPage === "facilities" && "about-btn-active"
                      }`}
                    >
                      <img src="/assets/img/about/facilites.png" alt="" />
                      <span className="d-none d-md-inline">Facilités</span>
                    </button>
                  </div>
                  <div className="col-3 col-md-12">
                    <button
                      onClick={() => setCurrentAboutPage("gallery")}
                      className={`btn btn1 ${
                        currentAboutPage === "gallery" && "about-btn-active"
                      }`}
                    >
                      <img src="/assets/img/about/gallery.png" alt="" />
                      <span className="d-none d-md-inline">Gallerie</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id={"rooms"} className="room-area">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="font-back-tittle mb-45">
                  <div className="archivment-front">
                    <h3>Nos chambres</h3>
                  </div>
                  <h3 className="archivment-back">Nos chambres</h3>
                </div>
              </div>
            </div>
            <div className="row">
              {data.chambres.map((ch) => (
                <div key={ch.id} className="col-xl-3 col-lg-6 col-md-6">
                  <div className="single-room mb-50">
                    <div className="room-img">
                      <img src={ch.image_hebergements[0]?.image} alt="" />
                    </div>
                    <div className="room-caption">
                      <h3>
                        <span>{ch.categorie}</span>
                      </h3>
                      <div className="d-flex flex-wrap">
                        {ch.room_accessories.map((ra) => (
                          <div key={ra.id} className="d-flex ml-4">
                            <img height={20} src={ra.image} alt="" />
                            {/* <small>{ra.label}</small> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row justify-content-center">
              <div className="room-btn pt-70">
                <a href="/reservation" className="btn border-btn">
                  Réservez <i className="ti-angle-right" />{" "}
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id={"gastronomie"} style={{ marginTop: 130 }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8">
                <div className="font-back-tittle mb-45">
                  <div className="archivment-front">
                    <h3>Gastronomie</h3>
                  </div>
                  <h3 className="archivment-back">Nos Restaurants</h3>
                </div>
              </div>
            </div>
            <div className="row">
              {data.gastronomie.map((ga) => (
                <div key={ga.id} className="col-12 col-md-6">
                  <div className="resto">
                    <img src={ga.image} alt="" />
                    <div className="resto-text">
                      <strong>
                        {ga.label} {ga.type}
                      </strong>
                      <ul className="gastro-details">
                        <li>
                          <strong>Supérficie:</strong>
                          {ga.superficie}
                        </li>
                        <li>
                          <strong>Couverts:</strong>
                          {ga.nb_couverts}
                        </li>
                        <li>
                          <strong>Places:</strong>
                          {ga.nb_places}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <div
          id={"conference-room"}
          className="dining-area dining-padding-top d-none d-md-block"
        >
          <div className="font-back-tittle mb-45">
            <div className="archivment-front">
              <h3>Salle dé conférences</h3>
            </div>
            <h3 className="archivment-back">Fêtes et évènements</h3>
          </div>
          <div className="single-dining-area left-img">
            <div
              className="image-salle-conference"
              style={{
                backgroundImage: `url("${data.salleConference[0]?.image}")`,
              }}
            />
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-lg-8 col-md-8">
                  <div className="dining-caption">
                    <span>Salle de conférences</span>
                    <h3>{data.salleConference[0]?.label}</h3>
                    <p>{data.salleConference[0]?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="conference-room-mobile"
          className="d-block d-md-none container"
        >
          <div className="font-back-tittle mb-45 mt-45">
            <div className="archivment-front">
              <h3>Salle dé conférences</h3>
            </div>
            <h3 className="archivment-back">Fêtes et évènements</h3>
          </div>
          <img src={data.salleConference[0]?.image} width={"100%"} alt="" />
          <div className="conference-details">
            <span>Salle de conférences</span>
            <h3
              style={{
                color: "#dca73a",
                fontize: "1.2rem",
                fontWeight: "bolder",
                fontStyle: "italic",
              }}
            >
              {data.salleConference[0]?.label}
            </h3>
            <p style={{ color: "black" }}>
              {data.salleConference[0]?.description}
            </p>
          </div>
        </div>

        <Contact data={data.informationsHotel} />
      </main>
      <footer>
        <div className="footer-area black-bg footer-padding">
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                <div className="single-footer-caption mb-30">
                  <div className="footer-logo">
                    <a href="index.html">
                      <h3 style={{ color: "#dca73a" }}>Hôtel Nesrine</h3>
                    </a>
                  </div>
                  <div className="footer-social footer-social2">
                    <a
                      href={data.informationsHotel.facebook}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      href={data.informationsHotel.instagram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                  <div className="footer-pera">
                    <p>
                      Copyright &copy;
                      <script>document.write(new Date().getFullYear());</script>
                      All rights reserved | This website is made with{" "}
                      <i className="ti-heart" aria-hidden="true"></i> by{" "}
                      <a href="#" target="_blank">
                        KREATEK.TN
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-5">
                <div className="single-footer-caption mb-30">
                  <div className="footer-tittle">
                    <h4>Liens rapides</h4>
                    <ul>
                      <li>
                        <a href="#about">À propos</a>
                      </li>
                      <li>
                        <a href="#rooms">Chambres</a>
                      </li>
                      <li>
                        <a href="#gastronomie">Gastronomie</a>
                      </li>
                      <li>
                        <a href="#conference-room">Salle de conférences</a>
                      </li>
                      <li>
                        <a href="/reservation">Réservation</a>
                      </li>
                      <li>
                        <a href="#contact">Contact</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                <div className="single-footer-caption mb-30">
                  <div className="footer-tittle">
                    <h4>Reservations</h4>
                    <ul>
                      <li>
                        <a href="#">Tel: {data.informationsHotel.tel1}</a>
                      </li>
                      <li>
                        <a href="#">email: {data.informationsHotel.email1}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4  col-sm-5">
                <div className="single-footer-caption mb-30">
                  <div className="footer-tittle">
                    <h4>Notre Localisation</h4>
                    <ul>
                      <li>{data.informationsHotel.localisation}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Client;
