import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  reservationsActions,
  reservationsStateSelector,
} from "../app/slice/reservations-slice";
import {
  searchResultActions,
  searchResultSelector,
} from "../app/slice/search-result.slice";
import AppModal from "../components/app-modal";
import { API_URL, APP_URL } from "../config/constants";
import SearchForm from "./search-form";
export default function Reservations({ onBackClicked }) {
  const [showForm, setShowForm] = useState(false);
  const [paiementLinkLoading, setPaiementLinkLoading] = useState(false);
  const [country_code, setCountry_code] = useState(null);
  const [clientForm, setClientForm] = useState({
    nom: "",
    email: "",
    tel: "",
    adresse: "",
  });
  const [showModal, setShowModal] = useState(false);
  const searchResultState = useSelector(searchResultSelector);
  const {
    dateRange,
    repartition,
    result: rooms,
    detailsReservation,
  } = searchResultState;
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [total, setTotal] = useState(0);
  useEffect(() => {
    fetch("https://geolocation-db.com/json/")
      .then((response) => response.json())
      .then(async (data) => {
        setCountry_code(data.country_code);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (rooms.length > 0) {
      setTotal(
        rooms
          .map((room, roomIndex) => {
            return { roomIndex, ...room.selectedHebergement };
          })
          .reduce(
            (a, b) =>
              a + b.arrangements[rooms[b.roomIndex].selectedArrangement],
            0
          )
      );
    }
  }, [rooms]);

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    function handleMessage(event) {
      if (event.data === "reloadPage") {
        window.location.reload();
      }
    }

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const submitReservation = async (e) => {
    e.preventDefault();

    setPaiementLinkLoading(true);
    // calcul de la somme après commission
    const total = rooms
      .map((room, roomIndex) => {
        return { roomIndex, ...room.selectedHebergement };
      })
      .reduce(
        (a, b) => a + b.arrangements[rooms[b.roomIndex].selectedArrangement],
        0
      );
    const env = process.env.NODE_ENV;
    const orderId = await axios
      .get(API_URL + "v2/reservation/get-max-voucher-sequence")
      .then((res) => res.data.orderId)
      .catch((error) => error);
    // generer le lien de paiement
    axios
      .post(
        env === "production"
          ? "https://api.konnect.network/api/v2/payments/init-payment"
          : "https://api.preprod.konnect.network/api/v2/payments/init-payment",
        {
          receiverWalletId:
            env === "production"
              ? "64d204df4760b71467dc3764"
              : "6496da5f1331dd46cb7455f5",
          token: "TND",
          amount: (total * (country_code === "TN" ? 1.023 : 1.05))
            .toFixed(3)
            .replace(".", ""),
          type: "immediate",
          lifespan: 30,
          addPaymentFeesToAmount: false,
          firstName: clientForm.nom,
          lastName: "",
          phoneNumber: clientForm.tel,
          email: clientForm.email,
          orderId,
          webhook: APP_URL + "confirmation-paiement-reservation",
          silentWebhook: false,
          checkoutForm: true,
          acceptedPaymentMethods: ["bank_card", "e-DINAR"],
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key":
              env === "production"
                ? "64d204df4760b71467dc3761:H3CyvSzafNZ0AbuEQbhU"
                : "6496da5f1331dd46cb7455f2:PxCtiPHlnaVidhaXR5Ea06z2mcyAA",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setPaiementLinkLoading(false);
          window.open(res.data.payUrl, "_blank");
        } else {
          console.log(res.data);
        }
      })
      .catch((error) => {
        setPaiementLinkLoading(false);

        console.log(error);
        alert(error.response.data.errors[0].message);
      });
    // garder les données de la réservation dans la mémoire
    // on paiement success => lien de succès redirection vers lien reservationSuccess
    // envoyer les données de la réservation vers le serveur
    // actualiser la page
    const reservation = {
      ...repartition,
      ...clientForm,
      nb_nuits: detailsReservation.nb_nuits,
      date_reservation: moment().format("YYYY-MM-DD"),
      date_debut_sejour: moment(detailsReservation.du).format("YYYY-MM-DD"),
      date_fin_sejour: moment(detailsReservation.au).format("YYYY-MM-DD"),
      total,
    };
    const hebergements = rooms.map((room) => {
      const { id, categorie: hebergement } = room.selectedHebergement;
      const { nb_adultes, nb_enfants, nb_bebes } = room.room;
      const arrangement = room.selectedArrangement;
      const prix = room.selectedHebergement.arrangements[arrangement];
      return {
        id,
        hebergement,
        nb_adultes,
        nb_enfants,
        nb_bebes,
        arrangement,
        prix,
      };
    });
    reservation.hebergements = hebergements;
    // console.log(reservation);
    localStorage.setItem("reservation", JSON.stringify(reservation));
  };
  return (
    <main id={"main"}>
      <div className="d-flex align-items-center justify-content-center flex-column">
        <Link to="/">
          <img height={100} src="logo.png" alt="nesrine hotel" />
        </Link>
        <h3>Réservation des chambres</h3>
      </div>
      {/* <div id={"rooms-header"}>
        <Link to={"/"} style={{ position: "relative" }}>
          <h6 className={"home-link"}>
            <i className={"bi bi-arrow-left"} />
            Acceuil
          </h6>
        </Link>
        <div className="container-fluid" style={{ position: "absolute" }}>
          <h2 style={{ color: "white" }}>Réservation des chambres</h2>
        </div>
      </div> */}
      <div className={"mt-4"} style={{ marginLeft: 8, marginRight: 8 }}>
        <SearchForm parent={"SearchResult"} />
      </div>
      <div className="container">
        {searchResultState.loading ? (
          <p>Chargement...</p>
        ) : (
          <div className="row">
            <div className="col-12 col-md-7">
              {searchResultState.result &&
              searchResultState.result.length > 0 ? (
                searchResultState.result.map((room, i) => (
                  <div className={"room-container"} key={i}>
                    <div className="room-container-title">
                      <div className="d-flex justify-content-between">
                        <h4>
                          Chambre {i + 1}
                          {[...Array(room.room.nb_adultes)].map(
                            (_, index_img) => (
                              <img
                                key={index_img}
                                src="assets/icons/adult.png"
                                alt=""
                              />
                            )
                          )}
                          {[...Array(room.room.nb_enfants)].map(
                            (_, index_img) => (
                              <img
                                key={index_img}
                                src="assets/icons/child.png"
                                alt=""
                              />
                            )
                          )}
                          {[...Array(room.room.nb_bebes)].map(
                            (_, index_img) => (
                              <img
                                key={index_img}
                                src="assets/icons/baby.png"
                                alt=""
                              />
                            )
                          )}
                        </h4>
                        <select
                          value={
                            searchResultState.result[i].selectedArrangement
                          }
                          onChange={(e) =>
                            dispatch(
                              searchResultActions.setSelectedArrangement({
                                roomIndex: i,
                                arrangement: e.target.value,
                              })
                            )
                          }
                        >
                          {room.arrangements.map((a) => (
                            <option key={a} value={a}>
                              {a}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {room.hebergements.length &&
                      room.hebergements.map((h, hi) => {
                        return (
                          <div key={hi} className="room row">
                            {/*image*/}
                            <div className="room-img col-12 col-md-5">
                              <i
                                style={{ cursor: "pointer", fontSize: 24 }}
                                onClick={() =>
                                  dispatch(
                                    searchResultActions.setSelectedHebergement({
                                      roomIndex: i,
                                      hebergement: h,
                                    })
                                  )
                                }
                                className={`bi ${
                                  room.selectedHebergement.categorie ===
                                  h.categorie
                                    ? "bi-check-square"
                                    : "bi-square"
                                }`}
                              />
                              <img
                                src={h.images[0]?.image}
                                alt=""
                                width={"100%"}
                                height={170}
                              />
                            </div>
                            {/*content*/}
                            <div className="room-content col-12 col-md-5">
                              {/*title*/}
                              <div className="room-title">
                                <h4>{h.categorie}</h4>
                              </div>
                              {/*specs*/}
                              <div className="room-specs mt-2">
                                {h.accessories?.map((ra) => (
                                  <div key={ra.id} className="d-flex ml-2">
                                    <img
                                      className="ml-3"
                                      height={20}
                                      src={ra.image}
                                      alt="ra"
                                    />
                                    {/* <span>{ra.label}</span> */}
                                  </div>
                                ))}
                              </div>
                            </div>
                            {/*price*/}
                            <div className="room-reservation col-12 col-md-2">
                              {/*price*/}
                              <div className="room-price">
                                <h6>
                                  {
                                    h.arrangements[
                                      searchResultState.result[i]
                                        .selectedArrangement
                                    ]
                                  }{" "}
                                  DT
                                </h6>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                ))
              ) : (
                <h3 className={"text-center mt-5"}>
                  Pas d'hébérgements disponibles
                </h3>
              )}
            </div>
            <div className="col-12 col-md-5">
              <div className="details-reservation">
                <div className="app-card">
                  <div className="app-card-title">
                    <span>Votre Réservation</span>
                  </div>
                  <div className="app-card-divs">
                    <div className="app-card-div">
                      <i className={"bi bi-calendar"} />
                      <div>
                        <strong>
                          {detailsReservation.du !== ""
                            ? moment(detailsReservation.du).format("LL")
                            : "Check-in"}
                        </strong>
                        <p>
                          {detailsReservation.du !== "" &&
                            moment(detailsReservation.du).format("dddd")}
                        </p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <i className={"bi bi-calendar"} />
                      <div>
                        <strong>
                          {detailsReservation.au !== ""
                            ? moment(detailsReservation.au).format("LL")
                            : "Check-out"}
                        </strong>
                        <p>
                          {detailsReservation.au !== "" &&
                            moment(detailsReservation.au).format("dddd")}
                        </p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <i className={"bi bi-moon"} />
                      <div>
                        {detailsReservation.nb_nuits}
                        <p>Nuité(s)</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="app-card">
                  <div className="app-card-title">
                    <span>
                      Répartitions{" "}
                      <strong>({detailsReservation.nb_chambres})</strong>{" "}
                      Chambre(s)
                    </span>
                  </div>
                  <div className="app-card-divs">
                    <div className="app-card-div">
                      <img src="assets/icons/adult.png" alt="" />
                      <div>
                        <strong>{detailsReservation.nb_adultes}</strong>
                        <p>Adulte(s)</p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <img src="assets/icons/child.png" alt="" height={20} />
                      <div>
                        <strong>{detailsReservation.nb_enfants}</strong>
                        <p>Enfant(s)</p>
                      </div>
                    </div>
                    <div className="app-card-div">
                      <img src="assets/icons/baby.png" alt="" />
                      <div>
                        <strong>{detailsReservation.nb_bebes}</strong>
                        <p>Bébé(s)</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="app-card">
                  <div className="app-card-title border-bottom-0">
                    {rooms && rooms.length > 0 ? (
                      <h3 style={{ textAlign: "center", color: "white" }}>
                        <strong>Total: </strong>
                        {rooms &&
                          rooms
                            .map((room, roomIndex) => {
                              return { roomIndex, ...room.selectedHebergement };
                            })
                            .reduce(
                              (a, b) =>
                                a +
                                b.arrangements[
                                  rooms[b.roomIndex].selectedArrangement
                                ],
                              0
                            )
                            .toFixed(3)}{" "}
                        DT{" "}
                        <p style={{ fontSize: ".7rem", color: "white" }}>
                          (Frais de paiement en ligne:{" "}
                          {(
                            total * (country_code === "TN" ? 1.023 : 1.05) -
                            total
                          ).toFixed(3)}{" "}
                          DT)
                        </p>
                      </h3>
                    ) : (
                      <h3 className={"text-center"}>0 DT</h3>
                    )}
                  </div>
                  <div className="app-card-divs">
                    <button
                      disabled={
                        searchResultState.result.length === 0 ||
                        !searchResultState.result.length
                      }
                      onClick={() => setShowForm(true)}
                      className="app-button"
                      style={{ width: "100%", paddingTop: 8, paddingBottom: 8 }}
                    >
                      JE RÉSERVE
                    </button>
                  </div>
                </div>
                {/*form réservation*/}
                {showForm && (
                  <AppModal
                    modalTitle={"Confirmez votre réservation"}
                    onCloseClicked={() => setShowForm(false)}
                    content={
                      <div className="app-card">
                        <div className="app-card-title">
                          <h5>Veuillez remplir ce formulaire</h5>
                        </div>
                        <form
                          className="confirmation-form"
                          onSubmit={submitReservation}
                        >
                          <div className="row mt-2">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label htmlFor="name">Nom et prénom</label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      nom: e.target.value,
                                    })
                                  }
                                  value={clientForm.nom}
                                  type="text"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <label htmlFor="name">Numéro téléphone</label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      tel: e.target.value,
                                    })
                                  }
                                  value={clientForm.tel}
                                  type="number"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="name">E-mail</label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      email: e.target.value,
                                    })
                                  }
                                  value={clientForm.email}
                                  type="email"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="name">Adresse</label>
                                <input
                                  onChange={(e) =>
                                    setClientForm({
                                      ...clientForm,
                                      adresse: e.target.value,
                                    })
                                  }
                                  value={clientForm.adresse}
                                  type="text"
                                  placeholder={""}
                                  className={"form-control"}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <button
                                disabled={paiementLinkLoading}
                                type={"submit"}
                                className={"app-button w-100 mt-2"}
                                style={{ paddingTop: 8, paddingBottom: 8 }}
                              >
                                {paiementLinkLoading
                                  ? "Chargement..."
                                  : "Passer au paiement"}
                              </button>
                            </div>
                          </div>
                          {message !== "" && (
                            <p style={{ color: "red" }}>{message}</p>
                          )}
                        </form>
                      </div>
                    }
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {showModal && (
        <AppModal
          modalTitle={"Message de confirmation"}
          content={
            <div>
              <p>Nous vous remercions d'avoir choisi notre hôtel.</p>
              <p>
                Votre réservation a été confirmée et nous avons envoyé un e-mail
                contenant le voucher de la réservation.
              </p>
              <strong className={"mt-2"}>
                Si vous avez des questions ou des préoccupations, n'hésitez pas
                à nous contacter. Nous sommes impatients de vous accueillir
                bientôt !
              </strong>
            </div>
          }
          onCloseClicked={() => window.location.reload()}
        />
      )}
    </main>
  );
}
