import React, { useEffect, useState } from "react";
import BaseService from "../../service/base.service";
import FileUploader from "../../components/file-uploader";
import { MdDeleteForever } from "react-icons/md";

const service = new BaseService("Gallery");

function Gallery() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    service.getAll().then((res) => setItems(res));
  };

  const handleCheck = (item) => {
    // Update item to display or not in the client
    service
      .save(item.id, { ...item, display: !item.display })
      .then((res) => getAll());
  };

  const handleDelete = (item) => {
    // Implement the logic to delete the item
    service.delete(item.id).then((res) => getAll());
  };

  const handleUpload = (src) => {
    // Implement the logic to upload the selected item
    service.create({ src, display: true }).then((res) => getAll());
  };

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|gif)$/.test(url);
  };

  return (
    <div className="container">
      <div className="row my-2">
        {items.map((item, index) => (
          <div key={index} className="col-12 col-md-3 mb-4">
            <div className="position-relative card shadow bg-light p-1">
              {isImage(item.src) ? (
                <img
                  src={item.src}
                  alt={`item-${index + 1}`}
                  width={"100%"}
                  height={150}
                />
              ) : (
                <video width={"100%"} height={150} controls>
                  <source src={item.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              <div className="d-flex justify-content-between">
                <div>
                  afficher
                  <input
                    type="checkbox"
                    checked={item.display}
                    onChange={() => handleCheck(item)}
                  />
                </div>
                <button className="badge" onClick={() => handleDelete(item)}>
                  <MdDeleteForever />
                </button>
              </div>
            </div>
          </div>
        ))}
        <div className="col-12 col-md-3">
          <FileUploader
            id="file-uploader"
            onUrlReceived={handleUpload}
            html={
              <label
                style={{
                  width: "100%",
                  height: 150,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "white",
                  color: "black",
                  border: "1px solid lightgray"
                }}
                htmlFor="file-uploader"
                className="btn btn-success"
              >
                +
              </label>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Gallery;
