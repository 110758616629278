import AdminPageTitle from "../../components/admin-page-title";
import { Link, Outlet } from "react-router-dom";
import BaseService from "../../service/base.service";
import { useEffect, useState } from "react";
const service = new BaseService("InformationHotel");
export default function ConditionsChambres() {
  const [hotel, setHotel] = useState(null);
  const updateHotelSourcePrix = (source_prix_chambre) => {
    service
      .save(hotel.id, { source_prix_chambre })
      .then((res) => setHotel({...hotel, source_prix_chambre}))
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    service
      .getAll()
      .then((res) => setHotel(res[0]))
      .catch((error) => console.error(error));
  }, []);
  if (!hotel) return <>chargement...</>;
  return (
    <div className={"container-fluid"}>
      <AdminPageTitle title={"Gestion de vente"}>
        <div className="row">
          <h3 className="mr-4">Source prix: </h3>
          <div className="form-check mr-3">
            <input
              checked={hotel.source_prix_chambre === "pax"}
              onChange={() => updateHotelSourcePrix("pax")}
              type="radio"
              className="form-check-input"
              name="srouce_prix_chambre"
              id="radio-pax"
            />
            <label htmlFor="radio-pax" className="form-check-label">
              Pax
            </label>
          </div>
          <div className="form-check">
            <input
              checked={hotel.source_prix_chambre === "chambre"}
              onChange={() => updateHotelSourcePrix("chambre")}
              type="radio"
              className="form-check-input"
              name="srouce_prix_chambre"
              id="radio-chambre"
            />
            <label htmlFor="radio-chambre" className="form-check-label">
              Chambre
            </label>
          </div>
        </div>
      </AdminPageTitle>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to={"conditions"} href="#">Conditions chambres</Link></li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to={"prix-chambre"}>Prix-Chambres</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to={"prix-chambre-pax"}>Prix-Pax</Link>
          </li>

          {/*<li className="breadcrumb-item"><Link to={"release-date"}>Release date</Link></li>*/}
          <li className="breadcrumb-item" aria-current="page">
            <Link to={"min-stay"}>Min Stay</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to={"stock"}>Stock</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to={"stop-sale"}>Stop Sale</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to={"promo"}>Promo</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to={"supplements"}>Suppléments</Link>
          </li>
          <li className="breadcrumb-item" aria-current="page">
            <Link to={"labels"}>Gestion des labels</Link>
          </li>
        </ol>
      </nav>

      <Outlet />
    </div>
  );
}
