import axios from "axios";
import { API_URL} from "../config/constants";

class BaseService {
  constructor(entity = "") {
    this.entity = entity;
  }

  async getAll() {
    return await axios
      .get(API_URL + this.entity)
      .then((res) => res.data)
      .catch((error) => error);
  }

  async save(id, data) {
    return await axios
      .put(`${API_URL}${this.entity}/${id}`, data, {
        headers: {
          "content-type": "application/json",
          "x-auth-token": localStorage.getItem('token'),
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  }
  async create(data) {
    return await axios
      .post(`${API_URL}${this.entity}`, data, {
        headers: {
          "content-type": "application/json",
          "x-auth-token": localStorage.getItem('token'),
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  }
  async findBy(by, byValue) {
    return await axios
      .post(
        `${API_URL}get-by/${this.entity}`,
        { by, byValue },
        {
          headers: {
            "content-type": "application/json",
            "x-auth-token": localStorage.getItem('token')
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => error);
  }
  async delete(id) {
    return await axios
      .delete(`${API_URL}${this.entity}/${id}`, {
        headers: {
          "x-auth-token": localStorage.getItem('token'),
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  }
  async getLandingData() {
    return await axios
      .get(`${API_URL}general/all/get-landing-data`)
      .then((res) => res.data)
      .catch((error) => error);
  }

  async postContact(data) {
    return await axios
      .post(`${API_URL}general/contact/send`, data, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  }

  async savePrixPax(data) {
    return await axios
      .post(
        `${API_URL}gestion-pax/create`,
        data,
        {
          headers: {
            "content-type": "application/json",
            "x-auth-token": localStorage.getItem('token')
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => error);
  }

  async updatePrixPax(data, id) {
    return await axios
      .put(
        `${API_URL}gestion-pax/update/${id}`,
        data,
        {
          headers: {
            "content-type": "application/json",
            "x-auth-token": localStorage.getItem('token')
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => error);
  }

  async getPrixPax(id) {
    return await axios
      .get(
        `${API_URL}gestion-pax/get/${id}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem('token')
          },
        }
      )
      .then((res) => res.data)
      .catch((error) => error);
  }
}



export default BaseService;
