import { useEffect, useState } from "react";
import { BiPlus, BiSave } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import BaseService from "../../../service/base.service";
import moment from "moment";

const supplementService = new BaseService("Supplement");
const paxService = new BaseService("PrixPax");
const PAX_IS = {
  label: "",
  du: "",
  au: "",
  ls: "",
  lpd: "",
  dp: "",
  pc: "",
  ai_soft: "",
  ai: "",
};
export default function Pax() {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [supplements, setSupplements] = useState([]);
  const [minsStayParArrangement, setMinsStayParArrangement] = useState([]);
  const [selectedArrangement, setSelectedArrangement] = useState("");
  const [pax, setPax] = useState(PAX_IS);
  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      pax,
      supplements,
      minsStay: minsStayParArrangement,
    };
    if (pax.id) {
      paxService
        .updatePrixPax(data, pax.id)
        .then((res) => {
          window.location.reload();
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    } else {
      paxService
        .savePrixPax(data)
        .then((res) => {
          window.location.reload();
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }
  };
  useEffect(() => {
    getAllPax();
  }, []);

  const getAllSupplements = () => {
    setLoading(true);
    supplementService
      .getAll()
      .then((res) => setSupplements(res))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };
  const getAllPax = () => {
    setLoading(true);
    paxService
      .getAll()
      .then((res) => setRows(res))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const submitMinStay = () => {
    const minStayRow = minsStayParArrangement.find(
      (row) => row.arrangement === selectedArrangement
    );
    if (minStayRow) return;
    setMinsStayParArrangement([
      ...minsStayParArrangement,
      { arrangement: selectedArrangement, min_stay: 1 },
    ]);
  };

  const updateMinStay = (arrangement, minStay) => {
    let minsStaysWU = [...minsStayParArrangement];
    const index = minsStaysWU.findIndex(
      (row) => row.arrangement === arrangement
    );
    if (index !== -1) {
      minsStaysWU[index].min_stay = minStay;
    }
    setMinsStayParArrangement(minsStaysWU);
  };

  const changeSupplementValue = (id, value) => {
    let newArray = [...supplements];
    const index = newArray.findIndex((o) => o.id === id);
    if (index !== -1) {
      newArray[index].valeur = value;
      setSupplements(newArray);
    }
  };

  const deletePax = (id) => {
    setLoading(true);
    paxService
      .delete(id)
      .then((res) => getAllPax())
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const onUpdatePaxClicked = (id) => {
    setLoading(true);
    paxService
      .getPrixPax(id)
      .then((res) => {
        const { pax, supplements, minsStay } = res;
        setPax(pax);
        setSupplements(supplements);
        setMinsStayParArrangement(minsStay);
        document.getElementById("button-open-modal-update").click();
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const resetForm = () => {
    setPax(PAX_IS);
    getAllSupplements();
    setMinsStayParArrangement([]);
  };

  return (
    <div className="row">
      <div className="col-12">
        <strong>Gestion des prix par pax</strong>
        <button
          id="button-open-modal"
          data-toggle="modal"
          data-target="#form"
          className="ml-2 btn btn-primary"
          onClick={resetForm}
        >
          <BiPlus />
          Ajouter
        </button>
        <button
          id="button-open-modal-update"
          data-toggle="modal"
          data-target="#form"
          className="d-none"
        >
          <BiPlus />
          Ajouter
        </button>
      </div>

      <div className="col-12">
        <table className="table table-bordered table-stripped table-hover mt-2">
          <thead className="table-dark">
            <tr style={{ fontSize: ".7rem", letterSpacing: 1 }}>
              <th>#</th>
              <th>Libéllé</th>
              <th>Date du</th>
              <th>Date au</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((r, index) => (
              <tr key={r.id}>
                <td>{index + 1}</td>
                <td>{r.label}</td>
                <td>{moment(r.du).format("DD/MM/YYYY")}</td>
                <td>{moment(r.au).format("DD/MM/YYYY")}</td>
                <td>
                  <button
                    disabled={loading}
                    onClick={() => onUpdatePaxClicked(r.id)}
                  >
                    Modifier
                  </button>
                  <button disabled={loading} onClick={() => deletePax(r.id)}>
                    Supprimer
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div
        className="modal fade"
        id="form"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Formulaire prix pax
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container">
                <form onSubmit={submit}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="input-label">Libéllé</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          value={pax.label}
                          onChange={(e) =>
                            setPax({ ...pax, label: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="input-du">Du</label>
                        <input
                          value={pax.du}
                          onChange={(e) =>
                            setPax({ ...pax, du: e.target.value })
                          }
                          id="input-du"
                          type="date"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="input-au">Au</label>
                        <input
                          value={pax.au}
                          onChange={(e) =>
                            setPax({ ...pax, au: e.target.value })
                          }
                          id="input-au"
                          type="date"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row border-top">
                    <div className="col-6 col-md-3">
                      <div className="form-group">
                        <label htmlFor="input-ls">LS</label>
                        <input
                          value={pax.ls}
                          onChange={(e) =>
                            setPax({ ...pax, ls: e.target.value })
                          }
                          id="input-ls"
                          type="number"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="form-group">
                        <label htmlFor="input-lpd">LPD</label>
                        <input
                          value={pax.lpd}
                          onChange={(e) =>
                            setPax({ ...pax, lpd: e.target.value })
                          }
                          id="input-lpd"
                          type="number"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="form-group">
                        <label htmlFor="input-dp">DP</label>
                        <input
                          value={pax.dp}
                          onChange={(e) =>
                            setPax({ ...pax, dp: e.target.value })
                          }
                          id="input-dp"
                          type="number"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="form-group">
                        <label htmlFor="input-pc">PC</label>
                        <input
                          value={pax.pc}
                          onChange={(e) =>
                            setPax({ ...pax, pc: e.target.value })
                          }
                          id="input-pc"
                          type="number"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="form-group">
                        <label htmlFor="input-aisoft">AI_SOFT</label>
                        <input
                          value={pax.ai_soft}
                          onChange={(e) =>
                            setPax({ ...pax, ai_soft: e.target.value })
                          }
                          id="input-aisoft"
                          type="number"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-6 col-md-3">
                      <div className="form-group">
                        <label htmlFor="input-ai">AI</label>
                        <input
                          value={pax.ai}
                          onChange={(e) =>
                            setPax({ ...pax, ai: e.target.value })
                          }
                          id="input-ai"
                          type="number"
                          className="form-control"
                        />
                      </div>
                    </div>
                    {supplements.map((supplement) => (
                      <div key={supplement.id} className="col-6 col-md-3">
                        <div className="form-group">
                          <label htmlFor={`input-${supplement.label}`}>
                            {supplement.label}
                          </label>
                          <input
                            value={supplement.valeur ?? ""}
                            onChange={(e) =>
                              changeSupplementValue(
                                supplement.id,
                                e.target.value
                              )
                            }
                            id={`input-${supplement.label}`}
                            type="number"
                            className="form-control"
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="row border-top pt-3">
                    <div className="col-12 mb-2">
                      <h6>
                        Min stays par arrangement:
                        <select
                          value={selectedArrangement}
                          onChange={(e) =>
                            setSelectedArrangement(e.target.value)
                          }
                        >
                          <option value="">Arrangement</option>
                          <option value="LS">LS</option>
                          <option value="LPD">LPD</option>
                          <option value="DP">DP</option>
                          <option value="PC">PC</option>
                          <option value="AI_SOFT">AI_SOFT</option>
                          <option value="AI">AI</option>
                        </select>
                        <button className="ml-2" type="button" onClick={submitMinStay}>
                          Ajouter
                        </button>
                      </h6>
                    </div>
                    <div className="col-12">
                      <table className="table table-sm table-bordered table-hover table-striped">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>arrangement</th>
                            <th>min stay</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {minsStayParArrangement.map((minStay, index) => (
                            <tr key={minStay.arrangement}>
                              <td>{index + 1}</td>
                              <td>{minStay.arrangement}</td>
                              <td>
                                <input
                                  type="number"
                                  value={minStay.min_stay}
                                  onChange={(e) =>
                                    updateMinStay(
                                      minStay.arrangement,
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td>
                                <button
                                  onClick={() =>
                                    setMinsStayParArrangement(
                                      minsStayParArrangement.filter(
                                        (row) =>
                                          row.arrangement !==
                                          minStay.arrangement
                                      )
                                    )
                                  }
                                >
                                  supprimer
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="modal-footer">
              <button
                id="button-close-modal"
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                <CgClose />
                Fermer
              </button>
              <button
                disabled={
                  loading ||
                  pax.label.trim() === "" ||
                  pax.du === "" ||
                  pax.au === ""
                }
                onClick={submit}
                type="button"
                className="btn btn-primary"
              >
                <BiSave />
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
